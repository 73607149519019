import { Images } from "../../ThemeConfig/Images";

export const Constants = {
  featuresSlider:[
    {
      id:'1',
      title:'Social Community',
      discription:"Engage in TGB's vibrant biker scene, share your ride adventures, learn from tech blogs and forums - always living the spirit of 'DO THE RIDE THING.'",
      image: `${Images?.featuresOne}`,
    },
    {
      id:'2',
      title:'Digital Garage',
      discription:"With our digital garage and future IoT & Blockchain integration, you can monitor your bike's condition comprehensively, keeping it tuned & ready to ride.",
      image: `${Images?.featuresTwo}`,
    },
    {
      id:'3',
      title:'Club Management',
      discription:"Participate in our decentralized bike clubs, engage in thrilling ride challenges - all custom-crafted for your spirit of adventure. Await an innovative transformation in club management - Coming soon.",
      image: `${Images?.featuresThree}`,
    },
    {
      id:'4',
      title:'Marketplace',
      discription:"Our impending blockchain-based marketplace will be your one-stop-shop for trading motorcycles, spare parts, and gear, crafting your ride into an exceptional journey.",
      image: `${Images?.featuresFour}`,
    },
    {
      id:'5',
      title:'Ride & Learn',
      discription:'Dive into exciting biking events, earn prestigious certifications, and revel in thrilling competitions. Never forget to "DO THE RIDE THING!"',
      image: `${Images?.featuresFive}`,
    },
  ]
}