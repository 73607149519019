import React from "react";

function SpotifyPodcasts() {
  return (
    <span>
      <svg id="bd58a441-b3d5-4ddd-a4a1-db755d3174e5" data-name="spotify" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.51 257.51"><defs><style>.f0f71ee2-f469-40b2-ada3-e57cae70e4c4</style></defs><path class="f0f71ee2-f469-40b2-ada3-e57cae70e4c4" d="M257.51,128.76A128.76,128.76,0,1,1,128.76,0,128.8,128.8,0,0,1,257.51,128.76Zm-34.37-25c0-6.18-2.65-9.4-6.7-11.73-29-16.93-68.74-24.82-106.63-24.82-22.28,0-42.73,2.54-61,7.89-4.67,1.35-9,5.35-9,12.41a12,12,0,0,0,12.1,12.25,24.6,24.6,0,0,0,6.69-1.35c42.78-11.94,108.87-6.64,145.84,15.42a12,12,0,0,0,18.74-10.07Zm-18.07,41.53c0-4.51-1.66-7.73-5.86-10.22-25.65-15.27-58.2-23.63-91.9-23.63a174.57,174.57,0,0,0-50.77,7.07c-5.35,1.5-8,5.19-8,10.74a10.08,10.08,0,0,0,10.07,10.07c2.34,0,3.69-.67,6.18-1.35,43-11.68,91.43-3.94,123.88,15.27,1.86,1,3.68,2.18,6.38,2.18A10.06,10.06,0,0,0,205.07,145.26Zm-16.14,36.19c0-4.72-1.87-6.54-5-8.57-37.07-22.16-80.52-23-123-13.6-3.9.83-7.06,3.38-7.06,8.72a8.08,8.08,0,0,0,8.2,8.21,33.66,33.66,0,0,0,6.18-1.35c37.22-7.63,74.91-6.8,107.31,12.72,2,1.19,3.38,1.87,5.56,1.87A7.85,7.85,0,0,0,188.93,181.45Z"/></svg>
    </span>
  );
}

export default SpotifyPodcasts;
