import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import './Faq.scss';
import { Helmet } from 'react-helmet';

const Faq = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='cmsPagesWrapper'>
    <Navemenu/>
    <Helmet>
      <meta charSet='utf-8'/>
      <title>FAQ - The Good Biker</title>
      <meta name='discription' content='Check information about privacy policy of The Good Biker.'/>
      <meta name='keywords' content='TGB Privacy Policy' />
    </Helmet>
    <div className='container'>
    <div className='row'>
      <div className='col-12 col-lg-12'>
      <div className="staticInfo">
      <h2>Frequently Asked Questions</h2>
      <div className="accordion" id="accordionExample">
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
            1. What is The Good Biker (TGB)?
          </button>
        </h2>
        <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
          <div className="accordion-body">
          TGB is an all-in-one platform designed for motorcycle enthusiasts, riders, and motorsport athletes. It offers a range of features and tools to enhance the biking experience.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            2. How do I sign up for TGB?
          </button>
        </h2>
        <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
          You can sign up by downloading the TGB app from the App Store (iOS) or Google Play Store (Android). Follow the registration process to create your account.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            3. Is TGB available for international users?
          </button>
        </h2>
        <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
          Yes, TGB is accessible to bikers and motorcycle enthusiasts worldwide.
          </div>
        </div>
      </div>
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            4. What is the Digital Garage feature?
          </button>
        </h2>
        <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
          <div className="accordion-body">
          The Digital Garage allows you to keep track of your motorcycle's maintenance, service records, and modifications. It's a personalized space for your bike.
          </div>
        </div>
      </div>
      <div className="accordion-item">
      <h2 className="accordion-header">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
          5. What is the Social Wall?
        </button>
      </h2>
      <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
        <div className="accordion-body">
        The Social Wall is a space to connect with fellow riders, share your biking experiences, photos, and engage with the vibrant TGB community.
        </div>
      </div>
    </div>
    <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
        6. What is the Marketplace on TGB?
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      TGB's Marketplace is a blockchain-based platform where you can buy and sell motorcycle-related products, accessories, and services securely.
      </div>
    </div>
  </div>
  <div className="accordion-item">
  <h2 className="accordion-header">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      7. Can I use TGB for motorsport-related activities?
    </button>
  </h2>
  <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    Absolutely! TGB supports motorsport athletes and enthusiasts. You can use TGB to connect with like-minded individuals, promote events, and more.
    </div>
  </div>
</div>
<div className="accordion-item">
<h2 className="accordion-header">
  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
    8. Is my data safe on TGB?
  </button>
</h2>
<div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
  <div className="accordion-body">
  Yes, TGB takes data privacy seriously. We employ robust security measures to protect your personal information.
  </div>
</div>
</div>
<div className="accordion-item">
<h2 className="accordion-header">
  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
    9. How can I report inappropriate content on the platform?
  </button>
</h2>
<div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
  <div className="accordion-body">
  You can report any inappropriate content or user behavior within the app. Our moderation team will review and take appropriate action.
  </div>
</div>
</div>
<div className="accordion-item">
<h2 className="accordion-header">
  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
    10. Is there a TGB support team I can contact?
  </button>
</h2>
<div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
  <div className="accordion-body">
  Yes, we have a dedicated support team ready to assist you. You can reach out through the app or our website.
  </div>
</div>
</div>
<div className="accordion-item">
<h2 className="accordion-header">
  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
    11. Can I join motorcycle clubs through TGB?
  </button>
</h2>
<div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
  <div className="accordion-body">
  Absolutely! TGB provides tools to create and join motorcycle clubs, making it easy to connect with other riders who share your interests.
  </div>
</div>
</div>
<div className="accordion-item">
<h2 className="accordion-header">
  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
    12. Where can I download the TGB app?
  </button>
</h2>
<div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
  <div className="accordion-body">
  You can download the TGB app from the App Store (iOS) or Google Play Store (Android).
  </div>
</div>
</div>
<div className="accordion-item">
<h2 className="accordion-header">
  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
    13. Is the TGB app available for tablets?
  </button>
</h2>
<div id="collapseThirteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
  <div className="accordion-body">
  Currently, TGB is optimized for smartphones. Tablet versions may be available in the future.
  </div>
</div>
</div>
      </div>
      </div>
    </div>
    </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Faq;