import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import { Images } from "../../ThemeConfig/Images";
import { Helmet } from 'react-helmet';
import './MotorcycleToursinIndia.scss';


const MotorcycleToursinIndia = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='siteMapWrapper'>
      <Navemenu/>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Motorcycle Journeys | The Good Biker</title>
        <meta name='discription' content='TGB is a specialized bikers group in India which offers the best motorcycle journeys.'/>
        <meta name='keywords' content='Motorcycle Journeys' />
      </Helmet>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className="staticInfo">
              <h1>Rev Up Your Adventures: Discover the Ultimate Motorcycle Journeys</h1>
              <p>Do you long for thrilling <b>motorcycle journeys?</b> We are aware of the thrill of riding a motorcycle down open highways. We do, however, recognize the particular difficulties that riding a motorcycle presents. In this post, we'll examine frequent rider problems while delving into the thrilling realm of motorcycle adventures. Come learn how TGB may make your travels more convenient and pleasurable as we explore this topic.</p>
              <div className='staticInfoList'>
                <div className='row'>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.indiaToursOne} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>The Beauty of Motorcycle Journeys</h4>
                        <p>Through their travels, motorcycle enthusiasts provide a unique view on the globe. Whether you're driving down serene beach lanes or twisting mountain roads, the beauty of the surroundings becomes an essential part of the journey. Every bend leads to a breath-taking new view. It might be challenging to find your way around these breathtaking paths, especially in uncharted country.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.indiaToursTwo} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Navigational Assistance</h4>
                        <p>It can be really frustrating to think of getting lost when riding a motorcycle. TGB does not sell navigation equipment, but we are dedicated to providing you with the best suggestions on the market. We will add offline maps for navigation in next TGB app upgrades to improve your riding experience.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.indiaToursThree} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Safety First - Choosing the Right Gear</h4>
                        <p>When traveling by motorbike, safety must always come first. Despite how much fun the trip is, it's important to have the proper protection. Poorly made or ill-fitting gear can make an adventure into a nightmare.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.indiaToursFour} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Gear Expertise</h4>
                        <p>Our main priority is your safety. We provide a carefully curated assortment of high-end accessories, including as jackets, gloves, helmets, and more, all of which are intended to improve your comfort and safety while riding. No matter the weather or unforeseen challenges, you may boldly explore with TGB's suggested equipment.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.indiaToursFive} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Discovering the Perfect Rest Spots</h4>
                        <p>One of the pleasures of motorcycle travel is discovering undiscovered treasures along the road. These rest areas can range from charming restaurants to breath-taking vistas, improving your trip's overall experience. Finding these treasures, though, can occasionally be a challenge.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.indiaToursSix} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Insider Advice</h4>
                        <p>Instead of just selling motorcycles, our aim is to create a community of enthusiastic riders. We give you exclusive access to a selected list of suggested lodgings and rest areas that has been shared by other riders. You can find undiscovered treasures and make lifelong travel memories by following our professional recommendations.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.indiaToursSeven} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Group Riding Made Effortless</h4>
                        <p>Due to the companionship and shared experiences they provide, group rides are preferred by many <b>motorcycle journeys.</b> However, planning a group ride can be difficult logistically, and staying in touch while driving might be difficult.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.indiaToursEight} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Advanced Features for Group Riding</h4>
                        <p>We have incorporated cutting-edge communication capabilities into our app since we recognize the benefits of group riding. Our integrated intercoms and group chat functions let you stay in constant communication with other riders. Whether you're traveling alone or with a sizable biking crew, <b>TGB</b> makes sure that everyone keeps to the timetable.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div className='staticBottomInfo'>
                <h4>Conclusion</h4>
                <p>Motorcycle touring is a symbol of freedom, camaraderie, and the making of cherished memories. We are aware of the difficulties faced by travelers and offer solutions to improve their safety and happiness while traveling. TGB is your go-to travel partner, providing group riding comforts, top-notch safety equipment, insider knowledge, and navigation assistance. Come along as we make every ride an amazing journey. Discover the Charm of Motorcycle Trips.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default MotorcycleToursinIndia;