import { Images } from "../../ThemeConfig/Images";

export const Constants = {
  riderSaySlider:[
    {
      id:'1',
      discription:"You'll get the best of what you put in. The team is working at their best to provide us variety of spaces and homes. Had a great experience working with them.",
      author:'Jatin Bhaskar',
      originated:'Bengaluru, Karnataka',
      image: `${Images?.riderSaying}`,
    },
    {
      id:'2',
      discription:"You'll get the best of what you put in. The team is working at their best to provide us variety of spaces and homes. Had a great experience working with them.",
      author:'Jatin Bhaskar',
      originated:'Bengaluru, Karnataka',
      image: `${Images?.riderSaying}`,
    },
    {
      id:'3',
      discription:"You'll get the best of what you put in. The team is working at their best to provide us variety of spaces and homes. Had a great experience working with them.",
      author:'Jatin Bhaskar',
      originated:'Bengaluru, Karnataka',
      image: `${Images?.riderSaying}`,
    },
  ]
}