import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import { Images } from "../../ThemeConfig/Images";
import { Helmet } from 'react-helmet';
import './ExperienceRiderTrainingIndia.scss';

const ExperienceRiderTrainingIndia = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='siteMapWrapper'>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Rider Training | Ride Experience in India - TGB</title>
        <meta name='discription' content='TGB provides the perfect rider training service as having a lot of ride experience in India.'/>
        <meta name='keywords' content='rider training, ride experience' />
      </Helmet>
      <Navemenu/>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className="staticInfo">
              <h1>Unlocking the Ultimate Rider Training and Ride Experience</h1>

              <div className='blockInfoBlue'>
                <p>Do you enjoy riding a lot, and are you always looking for methods to improve your abilities and riding experience? <b>TGB</b> (The good biker) recognizes the importance of excitement and safety for every ride on two wheels. However, a lot of bikers need more training and better riding experiences. These problems will be discussed, along with distinctive remedies that will change your riding experience.</p>
              </div>

              <div className='staticInfoList'>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.trainingOne} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Mastering the Art of Riding</h4>
                        <p>Many riders need more formal training or have received limited instruction, resulting in unsafe riding habits. We provide thorough <b>rider training</b> courses that cover everything from fundamental riding techniques to complex maneuvers.</p>
                        <p>To ensure that you develop into a competent and confident rider so that you can fully enjoy your excursions, our qualified instructors employ tried-and-true methods.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.trainingTwo} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Elevate Your Experience Riding</h4>
                        <p>The pleasure and variety that motorcycling can provide are frequently missed by riders who are mired in boring routines. We carefully crafted group rides, picturesque routes, and exhilarating events elevate your <b>ride experience</b> to a new level. Join our community of dedicated riders to discover new places, connect with like-minded people, and make priceless experiences.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.trainingThree} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Innovative Approach</h4>
                        <p>With our unique approach to <b>rider training</b> and experience riding, Our company separates apart from the competition. It's important to give riders both knowledge and abilities.</p>
                        <p>We make sure you're ready for any eventuality on the road by incorporating the most recent safety regulations and technical developments into our training programs. Whether you're a thrill-seeker or a leisure rider, Our rides are designed to appeal to a range of interests.</p>
                      </div>
                    </div>
                  </div>

                  <div className='col-12 col-lg-6'>
                    <div className='infoListGrid'>
                      <div className='image'>
                        <img src={Images.trainingFour} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Safety First, Always</h4>
                        <p>Safety is of the utmost importance when biking. Rider safety is a primary priority for us, and customers have access to the best safety gear available. Accident risk is decreased by the safe riding practices that are instilled by our teachers and become automatic. You may ride with our company in confidence knowing that your safety is our first priority.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='staticBottomInfo'>
                <h4>Join the TGB Community</h4>
                <p>We cultivate a community of dedicated riders who share their experiences and expertise; we don't just provide services. By becoming a member of TGB, you'll have access to a network of people who share your passion for biking. As you travel together, share experiences, advice, and ideas as you rediscover your passion for motorcycling.</p> 
                <p>The good biker is a movement devoted to enhancing the rider's journey, not just a business. We promise that your time with us will be remarkable because to our extensive training, exhilarating <b>ride experience</b>, creative approach, safety attention, and dynamic community. With us, bid riding issues farewell and welcome to the ultimate bicycle adventure. Begin your adventure with us right away, and let's ride together in the direction of excellence.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default ExperienceRiderTrainingIndia;