import React from "react";

function FacebookIcon() {
  return (
    <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="20" viewBox="0 0 10.5 20">
        <path id="Path_14916" data-name="Path 14916" d="M14,13.5h2.5l1-4H14v-2c0-1.029,0-2,2-2h1.5V2.14A28.074,28.074,0,0,0,14.643,2,4.336,4.336,0,0,0,10,6.7V9.5H7v4h3V22h4Z" transform="translate(-7 -2)"/>
      </svg>
    </span>
  );
}

export default FacebookIcon;
