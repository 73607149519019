import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import './Sitemap.scss';
import { Link } from "react-router-dom";
//import { Helmet } from 'react-helmet';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const Sitemap = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div>
      <HelmetProvider>
      <Helmet>
      <title>Site Map</title>
      <meta name='description' content='Beginner friendly page for learning React Helmet.' />
   </Helmet>
      </HelmetProvider>
   
    <div className='cmsPagesWrapper'>
    <Navemenu/>
    <div className='container'>
    <div className="staticInfo">
    <h2>Sitemap</h2>
    <div className='siteMapTree'>
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/blog">Blog</Link></li>
        <li><Link to="/policy">Privacy Policy</Link></li>
        <li><Link to="/terms">Terms & Conditions</Link></li>
        <li><Link to="/faq">FAQs</Link></li>
        <li>Pages
          <ul>
            <li><Link to="/indian-motorcycle-racing">Indian Racing Motorcycles</Link></li>
            <li><Link to="/biker-community-india">Biker Community India</Link></li>
            <li><Link to="/experience-rider-training-india">Experience Rider Training India</Link></li>
            <li><Link to="/motorcycle-tours-in-india">Motorcycle Tours In India</Link></li>
            <li><Link to="/digital-garage-india">Digital Garage India</Link></li>
            <li><Link to="/off-roading-adventures">Off Roading Adventures</Link></li>
            <li><Link to="/biker-social-network-india">Biker Social Network India</Link></li>
          </ul>
        </li>
      </ul>
    </div>
    </div>
    </div>
    <Footer/>
    </div>
    </div>
  );
};

export default Sitemap;