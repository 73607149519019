import React from "react";

function InstagramIcon() {
  return (
    <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_14913" data-name="Path 14913" d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0-2a5,5,0,1,1-5,5A5,5,0,0,1,12,7Zm6.5-.251A1.25,1.25,0,1,1,17.251,5.5,1.251,1.251,0,0,1,18.5,6.749ZM12,4c-2.475,0-2.878.007-4.029.058a5.44,5.44,0,0,0-1.8.332,2.9,2.9,0,0,0-1.081.7,2.89,2.89,0,0,0-.7,1.081,5.419,5.419,0,0,0-.332,1.8C4.007,9.075,4,9.461,4,12c0,2.474.007,2.878.058,4.029a5.444,5.444,0,0,0,.332,1.8,3.11,3.11,0,0,0,1.782,1.783,5.444,5.444,0,0,0,1.8.333c1.1.052,1.49.058,4.029.058,2.474,0,2.878-.007,4.029-.058a5.46,5.46,0,0,0,1.8-.332,3.11,3.11,0,0,0,1.784-1.782,5.442,5.442,0,0,0,.332-1.8c.052-1.1.058-1.49.058-4.029,0-2.474-.007-2.878-.058-4.029a5.46,5.46,0,0,0-.332-1.8,2.912,2.912,0,0,0-.7-1.081,2.884,2.884,0,0,0-1.081-.7,5.423,5.423,0,0,0-1.8-.332C14.926,4.006,14.54,4,12,4Zm0-2c2.717,0,3.056.01,4.123.06a7.357,7.357,0,0,1,2.427.465,4.872,4.872,0,0,1,1.772,1.153A4.908,4.908,0,0,1,21.476,5.45a7.4,7.4,0,0,1,.465,2.428C21.989,8.944,22,9.283,22,12s-.01,3.056-.06,4.122a7.381,7.381,0,0,1-.465,2.427,5.111,5.111,0,0,1-2.925,2.925,7.4,7.4,0,0,1-2.427.465c-1.067.047-1.406.06-4.123.06s-3.056-.01-4.123-.06a7.383,7.383,0,0,1-2.427-.465A5.11,5.11,0,0,1,2.526,18.55a7.378,7.378,0,0,1-.465-2.427C2.013,15.056,2,14.717,2,12s.01-3.056.06-4.122A7.352,7.352,0,0,1,2.526,5.45,4.879,4.879,0,0,1,3.679,3.678,4.9,4.9,0,0,1,5.451,2.525,7.374,7.374,0,0,1,7.878,2.06C8.945,2.013,9.284,2,12,2Z" transform="translate(-2.001 -2)"/>
      </svg>
    </span>
  );
}

export default InstagramIcon;
