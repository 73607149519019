import React from "react";

function YoutubeIcon() {
  return (
    <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
        <path id="Path_14914" data-name="Path 14914" d="M19.607,6.995a.813.813,0,0,0-.538-.592A38.688,38.688,0,0,0,12,6a38.681,38.681,0,0,0-7.069.4.813.813,0,0,0-.537.592A24.98,24.98,0,0,0,4,12,24.973,24.973,0,0,0,4.4,17.006a.812.812,0,0,0,.537.59A38.676,38.676,0,0,0,12,18a38.667,38.667,0,0,0,7.069-.4.813.813,0,0,0,.537-.592A25.02,25.02,0,0,0,20,12,25.02,25.02,0,0,0,19.607,6.995Zm1.937-.5A31.069,31.069,0,0,1,22,12a31.068,31.068,0,0,1-.457,5.5,2.811,2.811,0,0,1-1.939,2.022C17.9,20,12,20,12,20s-5.893,0-7.6-.476A2.811,2.811,0,0,1,2.458,17.5,31.071,31.071,0,0,1,2,12a31.071,31.071,0,0,1,.457-5.5A2.811,2.811,0,0,1,4.4,4.476C6.108,4,12,4,12,4s5.9,0,7.6.476A2.811,2.811,0,0,1,21.544,6.5ZM10,15.5v-7L16,12Z" transform="translate(-2.001 -4)"/>
      </svg>
    </span>
  );
}

export default YoutubeIcon;
