import React, {useEffect,useState} from 'react';
import { Link ,useParams,useNavigate} from "react-router-dom";
import { Images } from "../../ThemeConfig/Images";
import { Helmet } from 'react-helmet';
import './BlogDetail.scss';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import axios from "axios";
import moment from "moment";

const BlogDetail = () => {

  const navigate = useNavigate();
  const params = useParams();
  const [blogid , setBlogId] = useState(params.id)
  const [blogs , setBlogs] = useState([]);
  const [blogsimg , setBlogsImg] = useState('');
  useEffect(() => {
    getBlogBy(blogid);
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  const getBlogBy =(blogid)  =>{
    const url = 'https://x285-t7rb-jeug.n7.xano.io/api:a1md9bXd/blogs/'+blogid;
    console.log('start');
      axios.get(`${url}`)
     .then((response)=>{
       const result = response.data;
        console.log('result');
        console.warn(result);
        setBlogsImg(response.data.banner.url)
       setBlogs(response.data);
     })
     .catch(error=>console.error(`Error:${error}`))
  }

  // Conver editor code to hrml formate
  function UnsafeComponent({ html }) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  return (
    <div className='cmsPagesWrapper'>
      <Navemenu/>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Blog - The Good Biker</title>
        <meta name='discription' content='Check information about Blog posting of The Good Biker.'/>
        <meta name='keywords' content='TGB blogs' />
      </Helmet>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className="staticBlogsInfo">
                <div className='BlogDetailWrapper'>
                  <div className="BlogDetailImg">
                    <img src={blogsimg}  alt='blog-banner' className='img-fluid'/>
                    <div className="blogTagsInfo"><span>Posted On:</span> {moment(blogs.created_at).format("Do MMMM, YYYY")}</div>
                  </div>
                  <h3>{blogs.title}</h3>
                  <div className='BlogDetailInfo'><UnsafeComponent html={blogs.blog_description}/></div>
                  {/*<div dangerouslysetinnerhtml={{__html: this.blogs.blog_description}}></div>*/}
                </div>

                <div className='blogToMove'>
                  <div className='blogToMove__previous'>
                    <a href="" ><span>Previous</span></a>
                  </div>
                  <div className='blogToMove__next'>
                    <a href="" ><span>Next</span></a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
  <Footer/>
  </div>
  )
}
  export default BlogDetail;