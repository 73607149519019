export const Images = {
  logoImage: require('./../Assets/Images/logo.svg'),
  featuresOne: require('./../Assets/Images/features-one.jpg'),
  featuresTwo: require('./../Assets/Images/features-two.jpg'),
  featuresThree: require('./../Assets/Images/features-three.jpg'),
  featuresFour: require('./../Assets/Images/features-four.jpg'),
  featuresFive: require('./../Assets/Images/features-five.jpg'),
  appstore: require('./../Assets/Images/appstore.png'),
  googleplay: require('./../Assets/Images/googleplay.png'),
  riderSaying: require('./../Assets/Images/testimonial-avatar.png'),
  exploreTop: require('./../Assets/Images/exploreTop.png'),
  facebookIcon: require('./../Assets/Images/facebookIcon.svg'),
  indian_bg: require('./../Assets/Images/indian_bg.png'),
  indian_sm_bg: require('./../Assets/Images/indian_sm_bg.png'),
  indian_sm_bg1: require('./../Assets/Images/indian_sm_bg1.png'),
  indian_sm_bg2: require('./../Assets/Images/indian_sm_bg2.png'),
  indian_sm_bg3: require('./../Assets/Images/indian_sm_bg3.png'),
  indian_sm_bg4: require('./../Assets/Images/indian_sm_bg4.png'),

  communityOne: require('./../Assets/Images/community1.jpg'),
  communityTwo: require('./../Assets/Images/community2.jpg'),
  communityThree: require('./../Assets/Images/community3.jpg'),
  communityFour: require('./../Assets/Images/community4.jpg'),
  communityFive: require('./../Assets/Images/community5.jpg'),

  trainingOne: require('./../Assets/Images/training1.jpg'),
  trainingTwo: require('./../Assets/Images/training2.jpg'),
  trainingThree: require('./../Assets/Images/training3.jpg'),
  trainingFour: require('./../Assets/Images/training4.jpg'),

  indiaToursOne: require('./../Assets/Images/indiaTours1.jpg'),
  indiaToursTwo: require('./../Assets/Images/indiaTours2.jpg'),
  indiaToursThree: require('./../Assets/Images/indiaTours3.jpg'),
  indiaToursFour: require('./../Assets/Images/indiaTours4.jpg'),
  indiaToursFive: require('./../Assets/Images/indiaTours5.jpg'),
  indiaToursSix: require('./../Assets/Images/indiaTours6.jpg'),
  indiaToursSeven: require('./../Assets/Images/indiaTours7.jpg'),
  indiaToursEight: require('./../Assets/Images/indiaTours8.jpg'),

  digitalGarage: require('./../Assets/Images/digitalGarage.jpg'),

  adventuresOne: require('./../Assets/Images/adventures1.jpg'),
  adventuresTwo: require('./../Assets/Images/adventures2.jpg'),
  adventuresThree: require('./../Assets/Images/adventures3.jpg'),
  adventuresFour: require('./../Assets/Images/adventures4.jpg'),
  adventuresFive: require('./../Assets/Images/adventures5.jpg'),

  startupIndia: require('./../Assets/Images/startup.png'),
};