import React, {useState, useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Carousel from '../Carousel/Carousel';
import Features from '../Features/Features';
import RidersSaying from '../RidersSaying/RidersSaying';
import Footer from '../Footer/Footer';

const Home = () => {
//  useEffect(() => {
//   window.location.reload();
// },[]);
let[myload, setMyload] = useState(false);
setTimeout(() => {
  setMyload = true;
},1000);
  return (
    <>
      <Navemenu/>
      <Carousel/>
      <Features/>
      {/* <RidersSaying/> */}
      <Footer/>
    </>
  );
};

export default Home;