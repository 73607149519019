import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import { Images } from "../../ThemeConfig/Images";
import { Helmet } from 'react-helmet';
import './DigitalGarageIndia.scss';

const DigitalGarageIndia = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='siteMapWrapper'>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Digital Garage in India | The Good Biker</title>
        <meta name='discription' content='TGB has the best Digital Garage in India with future iOT and Blockchain Integration services.'/>
        <meta name='keywords' content='Digital Garage' />
      </Helmet>
      <Navemenu/>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className="staticInfo">
              <h1>Revolutionize Your Biking Experience with TGB's Digital Garage</h1>
              <div className='digitalIntro'>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <div className='digitalImage'>
                      <img src={Images.digitalGarage} alt="List_Image"/>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <p>Do you have problems with your beloved motorcycle that seem insurmountable? Do you want to search the internet for dependable answers? Look nowhere else! The <b>digital garage</b> we've created at The Good Biker is designed to meet all your biking demands.</p>
                    <p>We'll explore our wide range of services in this blog and how we might enhance your bike experience. A one-stop shop for all your bike problems is here, so say goodbye to the headache of countless searches and hello to it.</p>
                  </div>
                </div>
              </div>

              <div className='digitalTiles'>
                <div className='tiles'>
                  <h4>TGB's Virtual Bike Clinic</h4>
                  <p>Modern virtual bike clinics are housed in our digital storage facility, allowing you to diagnose and resolve problems while relaxing in your home. No need to struggle with difficult terminology or be concerned about being overcharged at a physical garage. Our simple interface will guide you through the procedure, ensuring you comprehend each step.</p>
                </div>
                <div className='tiles'>
                  <h4>Customized Maintenance Plans</h4>
                  <p>Not all bikes are the same, and neither are their maintenance requirements. <b>TGB</b> offers customized maintenance programs based on the make and model of your bike. Say goodbye to trite counsel and embrace a solution as one-of-a-kind as your vehicle.</p>
                </div>
                <div className='tiles'>
                  <h4>Sourcing Authentic Spare Parts</h4>
                  <p>Finding genuine spare parts for your motorcycle can be difficult. Our <b>digital garage</b> connects you with reputable vendors, ensuring you only receive genuine components. Say goodbye to counterfeits that jeopardize your bike's performance and safety.</p>
                </div>
                <div className='tiles'>
                  <h4>Biking Community Hub</h4>
                  <p>It's about more than simply the journey when you're a biker; it's about the friendship. Our platform supports a vibrant bike community where riders can connect, exchange stories, and seek assistance. Participate in discussions trade tales, and form long-lasting bonds.</p>
                </div>
                <div className='tiles'>
                  <h4>24/7 Roadside Assistance</h4>
                  <p>We don't keep normal hours, and neither do bike emergencies. We offer 24-hour roadside assistance to ensure you are never stranded. We're only a phone call away, ready to get you back on the road whether you have a flat tire, a dead battery, or any other problem.</p>
                </div>
              </div>

              <div className='staticBottomInfo'>
                <h4>Conclusion</h4>
                <p>Discover a new era of biking. Join TGB's digital garage, where solutions are customized, communities develop, and the well-being of your bike is our first focus. Ride with us with confidence!</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default DigitalGarageIndia;