import React from "react";

function Logo() {
  return (
    <span>
    <svg xmlns="http://www.w3.org/2000/svg" width="229.418" height="75.491" viewBox="0 0 229.418 75.491">
    <g id="Group_33728" data-name="Group 33728" transform="translate(-827 -677.854)">
      <path id="Path_14971" data-name="Path 14971" d="M3.111,0V-7.576H.281V-8.59H7.09v1.014H4.248V0ZM8.221,0V-8.59H9.932l2.033,6.082q.281.85.41,1.271.146-.469.457-1.377L14.889-8.59h1.529V0h-1.1V-7.189L12.826,0H11.8L9.316-7.312V0Z" transform="translate(1040 688.854)" fill="#0e0f0f"/>
      <g id="Group_33543" data-name="Group 33543" transform="translate(827 677.854)">
        <g id="Group_33532" data-name="Group 33532" transform="translate(0 62.496)">
          <path id="Path_14686" data-name="Path 14686" d="M216.505,129.507H229.76l-.612,3.026H222.52l2.172.65-1.875,9.319h-4.326l1.986-9.969h-4.585Z" transform="translate(-215.893 -129.507)" fill="#4baddf"/>
          <path id="Path_14687" data-name="Path 14687" d="M243.935,137.527h-8.8l2.172.649-.872,4.326h-3.9l2.6-13h3.9l-1,4.976h6.5l1-4.976h3.9l-2.6,13h-3.9Z" transform="translate(-218.576 -129.507)" fill="#4baddf"/>
          <path id="Path_14688" data-name="Path 14688" d="M256.679,132.533l2.173.65-.279,1.411h6.943l-.556,2.822H258l-.3,1.541a.423.423,0,0,0,.094.362.41.41,0,0,0,.334.158h7.686l-.595,3.026h-9.487a2.14,2.14,0,0,1-.955-.214,2.058,2.058,0,0,1-.724-.575,2.278,2.278,0,0,1-.418-.835,2.155,2.155,0,0,1-.038-.975l2.079-10.4h12.141l-.612,3.026Z" transform="translate(-221.965 -129.507)" fill="#4baddf"/>
          <path id="Path_14689" data-name="Path 14689" d="M282.323,133.183l-1.152,5.773a.42.42,0,0,0,.093.362.411.411,0,0,0,.334.158h5.513a.405.405,0,0,0,.269-.1.469.469,0,0,0,.158-.251l.353-1.819h-1.615a1.233,1.233,0,0,1-.558-.13,1.325,1.325,0,0,1-.436-.344,1.28,1.28,0,0,1-.251-.5,1.32,1.32,0,0,1-.018-.585l.2-1.04h7.091l-.984,5.013a3.509,3.509,0,0,1-.445,1.123,3.626,3.626,0,0,1-.762.882,3.329,3.329,0,0,1-1.012.576,3.46,3.46,0,0,1-1.179.2h-9.041a1.926,1.926,0,0,1-.835-.185,1.825,1.825,0,0,1-.65-.511,1.993,1.993,0,0,1-.371-.733,1.807,1.807,0,0,1-.019-.854l1.8-8.967a2.074,2.074,0,0,1,.743-1.262,2.112,2.112,0,0,1,1.373-.483h12.086l-.613,3.026H280.151Z" transform="translate(-225.74 -129.507)" fill="#4baddf"/>
          <path id="Path_14690" data-name="Path 14690" d="M310.332,129.507a2.109,2.109,0,0,1,.937.214,2.168,2.168,0,0,1,.734.575,2.235,2.235,0,0,1,.427.826,1.953,1.953,0,0,1,.018.965l-1.726,8.67a2.068,2.068,0,0,1-.752,1.262,2.151,2.151,0,0,1-1.384.482h-9.431a2.138,2.138,0,0,1-.955-.214,2.058,2.058,0,0,1-.724-.575,2.278,2.278,0,0,1-.418-.835,2.153,2.153,0,0,1-.038-.975l1.745-8.65a2.069,2.069,0,0,1,.742-1.262,2.114,2.114,0,0,1,1.374-.483ZM300.1,132.533l2.172.65-1.151,5.773a.426.426,0,0,0,.093.362.411.411,0,0,0,.334.158h7.817l-2.154-.649,1.151-5.773a.425.425,0,0,0-.093-.362.414.414,0,0,0-.334-.158Z" transform="translate(-228.966 -129.507)" fill="#4baddf"/>
          <path id="Path_14691" data-name="Path 14691" d="M329.72,129.507a2.112,2.112,0,0,1,.937.214,2.157,2.157,0,0,1,.733.575,2.225,2.225,0,0,1,.427.826,1.943,1.943,0,0,1,.019.965l-1.727,8.67a2.063,2.063,0,0,1-.751,1.262,2.155,2.155,0,0,1-1.384.482h-9.43a2.144,2.144,0,0,1-.956-.214,2.073,2.073,0,0,1-.724-.575,2.289,2.289,0,0,1-.417-.835,2.143,2.143,0,0,1-.038-.975l1.746-8.65a2.07,2.07,0,0,1,.742-1.262,2.113,2.113,0,0,1,1.373-.483Zm-10.229,3.026,2.172.65-1.151,5.773a.42.42,0,0,0,.093.362.411.411,0,0,0,.334.158h7.816l-2.153-.649,1.151-5.773a.427.427,0,0,0-.093-.362.414.414,0,0,0-.334-.158Z" transform="translate(-232.091 -129.507)" fill="#4baddf"/>
          <path id="Path_14692" data-name="Path 14692" d="M347.735,142.5H335.761l2.6-13h12.512a2.155,2.155,0,0,1,.956.214,2.076,2.076,0,0,1,.724.575,2.322,2.322,0,0,1,.418.826,2.1,2.1,0,0,1,.037.965l-1.466,7.277a3.735,3.735,0,0,1-.482,1.262,4.031,4.031,0,0,1-.854.993,3.831,3.831,0,0,1-2.469.882Zm-.037-3.379,1.206-6.07a.44.44,0,0,0-.084-.362.387.387,0,0,0-.325-.158h-9l2.024.65-1.244,6.293h7a.406.406,0,0,0,.269-.1A.475.475,0,0,0,347.7,139.124Z" transform="translate(-235.217 -129.507)" fill="#4baddf"/>
          <path id="Path_14693" data-name="Path 14693" d="M376.284,142.5H362.805l2.6-13h11.641a2.159,2.159,0,0,1,.956.214,2.084,2.084,0,0,1,.724.575,2.337,2.337,0,0,1,.418.826,1.963,1.963,0,0,1,.018.965l-.408,2.1a1.755,1.755,0,0,1-.6.993,1.66,1.66,0,0,1-1.1.4h.279a1.681,1.681,0,0,1,.761.176,1.752,1.752,0,0,1,.586.464,1.813,1.813,0,0,1,.333.659,1.672,1.672,0,0,1,.028.761l-.632,3.12a2.075,2.075,0,0,1-.742,1.262A2.112,2.112,0,0,1,376.284,142.5Zm-1.8-8.224a.413.413,0,0,0,.427-.352l.167-.872a.424.424,0,0,0-.092-.362.415.415,0,0,0-.335-.158h-8.037l2.172.65-.223,1.095Zm.2,5.087a.452.452,0,0,0,.148-.242l.334-1.726a.442.442,0,0,0-.083-.362.39.39,0,0,0-.326-.158h-6.72l-.519,2.6h6.887A.423.423,0,0,0,374.687,139.365Z" transform="translate(-239.577 -129.507)" fill="#4baddf"/>
          <path id="Path_14694" data-name="Path 14694" d="M383.256,142.5l2.6-13h3.9l-.612,3.026h-2.284l2.154.65-1.857,9.319Z" transform="translate(-242.874 -129.507)" fill="#4baddf"/>
          <path id="Path_14695" data-name="Path 14695" d="M398.329,137.23l-3.843,2.989,1.95-.965-.651,3.249h-3.9l2.6-13h3.9l-.928,4.66,6.349-4.66h4.548l-7.017,5.4L406.4,142.5h-4.549Z" transform="translate(-244.266 -129.507)" fill="#4baddf"/>
          <path id="Path_14696" data-name="Path 14696" d="M414.415,132.533l2.172.65-.278,1.411h6.943l-.557,2.822h-6.962l-.3,1.541a.423.423,0,0,0,.093.362.411.411,0,0,0,.334.158h7.687l-.595,3.026h-9.487a2.137,2.137,0,0,1-.955-.214,2.058,2.058,0,0,1-.724-.575,2.277,2.277,0,0,1-.418-.835,2.143,2.143,0,0,1-.038-.975l2.079-10.4h12.141l-.613,3.026Z" transform="translate(-247.394 -129.507)" fill="#4baddf"/>
          <path id="Path_14697" data-name="Path 14697" d="M438.02,138.827h-4.753l-.742,3.676h-3.9l2.6-13h12.513a2.152,2.152,0,0,1,.955.214,2.067,2.067,0,0,1,.724.575,2.324,2.324,0,0,1,.419.826,2.122,2.122,0,0,1,.037.965l-1,4.993a2.148,2.148,0,0,1-2.115,1.746h-.836l1.652,3.676h-3.9Zm2.859-3.045a.443.443,0,0,0,.427-.334l.464-2.395a.443.443,0,0,0-.084-.362.388.388,0,0,0-.325-.158h-9.134l2.153.65-.52,2.6Z" transform="translate(-250.188 -129.507)" fill="#4baddf"/>
        </g>
        <g id="Group_33533" data-name="Group 33533" transform="translate(11.419 0)">
          <path id="Path_14698" data-name="Path 14698" d="M312.806,80.082l-3.053,11.082H337.54l-2.078,7.792H315.147a18.577,18.577,0,0,1-6.1-.925,11.194,11.194,0,0,1-4.32-2.611,8.144,8.144,0,0,1-2.213-4.056,10.97,10.97,0,0,1,.235-5.258,14.761,14.761,0,0,1,2.611-5.258c.2-.262.417-.514.633-.764H288.375a29.857,29.857,0,0,0-1.968,5.3,22.741,22.741,0,0,0-.455,10.88,18.618,18.618,0,0,0,4.433,8.674,22.477,22.477,0,0,0,8.71,5.742,33.965,33.965,0,0,0,12.367,2.092H348.32l8.715-32.685Z" transform="translate(-238.532 -59.044)" fill="#f72"/>
          <path id="Path_14699" data-name="Path 14699" d="M425.965,63.39a8.972,8.972,0,0,0-3.017-4.654,14.672,14.672,0,0,0-5.913-2.811A34.53,34.53,0,0,0,408.566,55H233.4l-3.89,13.968H256.86l-10.6,39.755H262.88l10.6-39.755H405.564a5.945,5.945,0,0,1,3.024.726c.833.484,1.068,1.395.712,2.733a4.084,4.084,0,0,1-2.163,2.846,7.932,7.932,0,0,1-3.458.768H356.145l-8.717,32.683h47.213a42.874,42.874,0,0,0,10.887-1.21,24.482,24.482,0,0,0,7.578-3.288,17.024,17.024,0,0,0,4.853-4.938,22.489,22.489,0,0,0,2.7-6.226q1.28-4.814-.349-7.869a8.244,8.244,0,0,0-4.9-4.1,15.131,15.131,0,0,0,3.067-1.4,14.515,14.515,0,0,0,2.952-2.327,18.651,18.651,0,0,0,2.512-3.252,16.259,16.259,0,0,0,1.779-4.255A12.514,12.514,0,0,0,425.965,63.39ZM404.553,91.133a4.676,4.676,0,0,1-1.949,2.811,7.646,7.646,0,0,1-4.2.968H367.815l1.971-7.393h30.591c1.821,0,3.052.313,3.692.925A2.584,2.584,0,0,1,404.553,91.133Z" transform="translate(-229.507 -55)" fill="#f72"/>
        </g>
      </g>
    </g>
  </svg>
    </span>
  );
}

export default Logo;
