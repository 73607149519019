import React from 'react';
import { Images } from "../../ThemeConfig/Images";
import { Constants } from './Constants';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Features.scss';


const options = {
  margin:20,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay:true,
  loop:true,
  autoplaySpeed:2000,
  navSpeed:2000,
  autoplayHoverPause:true,
  responsive:{
    0:{
      items:1,
    },
    600:{
      items:2,
    },
    1000:{
      items:4,
    },
  },
}


const Features = () => {
  return (
    <div className='featuresWrapper'>
    <div className='featuresInnWrapper'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-4'>
            <h2 className="topTitleRight">The Good Biker Features</h2>
          </div>
          <div className='col-12 col-lg-8'>
            <p className='topTitleLeft'>Emerging as the ultimate digital biking hub, The Good Biker reshapes motorcycle networking, maintenance, and commerce, fostering a vibrant, extensive community of biking enthusiasts</p>
          </div>
          <div className='col-12 col-lg-12'>
            <div className='xyz'>
              <ReactOwlCarousel className='owl-theme carouselFeatures' {...options}>
              {
                Constants?.featuresSlider?.map((data) => (
                  <div className='itemCover' key={data?.id}>
                    <div className='item'>
                      <div className='bannerImg'>
                        <img src={data?.image} alt={data?.title ?? ''} className='image'/>
                        <div className="middle">&nbsp;</div>
                      </div>
                      <div className='bannerText'>
                        <h2>{data.title}</h2>
                        <p>{data.discription} </p>
                      </div>
                    </div>
                  </div>
                ))
              }
              </ReactOwlCarousel>
            </div>
          </div>
          <div className='col-12 col-lg-12'>
            <div className='appWrapper'>
              <div className='row'>
                <div className='col-12 col-lg-6'>
                  <div className='appWrapper__right'>
                    <h2>Explore The Good Biker Mobile App</h2>
                    <p>The Good Biker is emerging as a premier digital hub for bikers, integrating social interaction, condition monitoring, and an upcoming blockchain marketplace. Join us by downloading The Good Biker App now.</p>
                    <div className='appBtns'>
                      <img src={Images.appstore} alt='appstore' className='img-fluid'/>
                      <img src={Images.googleplay} alt='googleplay' className='img-fluid'/>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-lg-6'>
                <div className='appWrapper__left'>
                  <div className='innerImage'>&nbsp;</div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Features;