import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import { Images } from "../../ThemeConfig/Images";
import { Helmet } from 'react-helmet';
import './OffRoadingAdventures.scss';


const OffRoadingAdventures = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='siteMapWrapper'>
      <Navemenu/>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Off Roading Adventures - The Good Biker</title>
        <meta name='discription' content='TGB has the vast experience in off roading adventures in India. For Any queries get in touch with us.'/>
        <meta name='keywords' content='Off roading adventures' />
      </Helmet>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className="staticInfo">
              <h1>Off-Roading Adventures with TGB: Conquer the Wild in Style</h1>
              <div className='digitalIntro'>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <div className='digitalImage'>
                      <img src={Images.adventuresOne} alt="List_Image"/>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <p>Are you sick and tired of living a routine life? Do you yearn for excitement and the unknown? The Good Biker is the place to go if you've ever imagined escaping the concrete jungle and going on heart-pounding <b>off roading adventures</b>. We'll go into the thrilling world of off-roading, emphasizing the distinctive experiences we offer and how we may address the issues that frequently arise when seeking such adventures.</p>
                  </div>
                </div>
              </div>
              <div className='adventuresTiles'>
                <div className='row'>
                  <div className='col-12 col-lg-6'>
                    <div className='tiles'>
                      <div className='image'>
                        <img src={Images.adventuresTwo} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Good Biker Difference</h4>
                        <p>There are several off-roading experiences, and not all are created equal. We take pride in being unique. Your safety and comfort are guaranteed by our fleet of tough cars and trucks that were built specifically for off-road conditions. Whether you're an experienced off-roader or a complete novice, our knowledgeable guides will adjust your journey to suit your experience level, making every trip unforgettable.</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <div className='tiles'>
                      <div className='image'>
                        <img src={Images.adventuresThree} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Breathtaking Scenic Routes</h4>
                        <p>The magnificent picturesque roads we offer are one of the <b>off roading adventures</b> most notable qualities. We transport you to uncharted territory, revealing undiscovered treasures and breathtaking vistas that will astound you. Our routes offer a diversity of terrains for a unique and fascinating experience, from deep woods to enormous desert sands.</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <div className='tiles'>
                      <div className='image'>
                        <img src={Images.adventuresFour} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Adventure for All</h4>
                        <p>Off-roading isn't just for thrill seekers and daredevils. We at TGB cater to all outdoor lovers so everyone can experience the excitement of off-roading. Our professionally organized excursions offer choices for families, couples, and lone travelers, ensuring that everyone may enjoy themselves.</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-lg-6'>
                    <div className='tiles'>
                      <div className='image'>
                        <img src={Images.adventuresFive} alt="List_Image"/>
                      </div>
                      <div className='info'>
                        <h4>Eco-Friendly Off-Roading</h4>
                        <p>We put environmental preservation first since we are fervent off-roading lovers. <b>TGB</b> is dedicated to eco-friendly procedures and instructs participants in safe off-roading.</p>
                        <p>Our cars are built to have as little impact on the environment as possible so that future generations can appreciate these natural treasures. Discover a unique, eco-friendly off-roading adventure with The Good Biker. Join us to conquer the wilderness in style and leave your worries behind.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default OffRoadingAdventures;