import Home from './components/Home/Home';
import Terms from './components/Terms/Terms';
import Policy from './components/Policy/Policy';
import Faq from './components/Faq/Faq';
import IndianMotorcycleRacing from './components/IndianMotorcycleRacing/IndianMotorcycleRacing';
import BikercommunityIndia from './components/BikercommunityIndia/BikercommunityIndia';
import ExperienceRiderTrainingIndia from './components/ExperienceRiderTrainingIndia/ExperienceRiderTrainingIndia';
import MotorcycleToursinIndia from './components/MotorcycleToursinIndia/MotorcycleToursinIndia';
import DigitalGarageIndia from './components/DigitalGarageIndia/DigitalGarageIndia';
import BikerSocialNetworkIndia from './components/BikerSocialNetworkIndia/BikerSocialNetworkIndia';
import OffRoadingAdventures from './components/OffRoadingAdventures/OffRoadingAdventures';
import Sitemap from './components/Sitemap/Sitemap';
import Blog from './components/Blog/Blog';
import { Helmet } from 'react-helmet';


import NotFound from './components/NotFound/NotFound';
import './App.scss';

import {
  Routes,
  Route,
} from "react-router-dom";
import BlogDetail from './components/BlogDetail/BlogDetail';


function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Bikers Group in India - The Good Biker</title>
        <meta name='discription' content='The Good Biker (TGB) is a Group of Indian bikers who enjoy riding motorcycles and spending time together.'/>
        <meta name='keywords' content='Bikers Group in India, The Good Biker' />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="index.html" element={<Home/>} />
        <Route path="terms" element={<Terms />} />
        <Route path="policy" element={<Policy />} />
        <Route path="faq" element={<Faq/>} />
        <Route path="blog" element={<Blog/>} />
        <Route path="blog/:id" element={<BlogDetail/>} />
        <Route path="indian-motorcycle-racing" element={<IndianMotorcycleRacing />} />
        <Route path="biker-community-india" element={<BikercommunityIndia />} />
        <Route path="experience-rider-training-india" element={<ExperienceRiderTrainingIndia/> } />
        <Route path="motorcycle-tours-in-india" element={<MotorcycleToursinIndia/>} />
        <Route path="digital-garage-india" element={<DigitalGarageIndia/>} />
        <Route path="biker-social-network-india" element={<BikerSocialNetworkIndia/>} />
        <Route path="off-roading-adventures" element={<OffRoadingAdventures/>} />
        <Route path="sitemap" element={<Sitemap/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
