import React from "react";

function LinkedinIcon() {
  return (
    <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="19.06" height="18.001" viewBox="0 0 19.06 18.001">
        <path id="Path_14915" data-name="Path 14915" d="M6.94,5A2,2,0,1,1,5,3,2,2,0,0,1,6.94,5ZM7,8.48H3V21H7Zm6.32,0H9.34V21h3.94V14.43c0-3.66,4.77-4,4.77,0V21H22V13.07c0-6.17-7.06-5.94-8.72-2.91Z" transform="translate(-2.94 -2.999)"/>
      </svg>
    </span>
  );
}

export default LinkedinIcon;
