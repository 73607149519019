import React from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import { Footerlogo } from "../../components/Icons";
import './NotFound.scss';

const NotFound = () => {
  return (
    <div className='cmsPagesWrapper'>
     <Navemenu/>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className='noInfoWrapper'>
              <Footerlogo/>
              <h2>404 Page not found</h2>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default NotFound;