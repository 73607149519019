import React, { useState, useEffect } from 'react';
import { FacebookIcon, InstagramIcon, YoutubeIcon, LinkedinIcon, Logo, MenuIcon, SpotifyPodcasts } from "../../components/Icons";
import { Link } from "react-router-dom";
import './Navemenu.scss'

const Navemenu = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Function to handle scroll events
    function handleScroll() {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
  <div className='customNavmenu'>
  <nav className={`navbar navbar-expand-lg fixed-top ${isScrolled ? 'scrolled' : ''}`}>
  <div className="container-fluid">
  <div className='navMenuInner'>
    <div className='navMenu'>
      <div className="outer-menu">
        <input className="checkbox-toggle" type="checkbox" />
        <div className="hamburger">
          <div></div>
        </div>
        <div className="menu">
          <div>
            <div>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/Blog">Blogs</Link></li>
                {/* <li><Link to="/Indian-motorcycle-racing">Indian Racing Motorcycles</Link></li> */}
                <li><Link to="/Policy">Privacy Policy</Link></li>
                <li><Link to="/Terms">Terms & Conditions</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className='navLogo'>
      <Link to="/"><Logo/></Link>
    </div>
    <div className="socialLinks">
      <ul>
        <li><Link to='https://www.facebook.com/thegoodbiker/' target='_blank'><FacebookIcon/></Link></li>
        <li><Link to='https://www.instagram.com/iamthegoodbiker/' target='_blank'><InstagramIcon/></Link></li>
        <li><Link to='https://www.youtube.com/channel/UCiqcV_lTuE29iBEiWs2OUlg' target='_blank'><YoutubeIcon/></Link></li>
        <li><Link to='https://in.linkedin.com/company/the-good-biker' target='_blank'><LinkedinIcon/></Link></li>
        <li><Link to='#' target='_blank'><SpotifyPodcasts/></Link></li>
      </ul>
    </div>
  </div>
    {/*<a className="navbar-brand" href="#">Navbar</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#">Link</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li className="dropdown-divider"></li>
            <li><a className="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link disabled">Disabled</a>
        </li>
      </ul>
      <ul className="d-flex socialLinks">
        <li><a href=''><FacebookIcon/></a></li>
        <li><a href=''><InstagramIcon/></a></li>
        <li><a href=''><YoutubeIcon/></a></li>
        <li><a href=''><LinkedinIcon/></a></li>
  </ul>
    </div>*/}
  </div>
  </nav>
  {/* <nav className="navbar navbar-expand-lg navbar-light bg-light shadow">
    <div className="container-fluid">
      <a className="navbar-brand" href="#">Bootstrap <span className="badge bg-primary">v5.2.0-beta1</span></a>
      <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content">
        <div className="hamburger-toggle">
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </button>
      <div className="collapse navbar-collapse" id="navbar-content">
        <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Multilevel</a>
            <ul className="dropdown-menu shadow">
              <li><a className="dropdown-item" href="#">Gallery</a></li>
              <li><a className="dropdown-item" href="blog.html">Blog</a></li>
              <li className="dropstart">
                <a href="#" className="dropdown-item dropdown-toggle" data-bs-toggle="dropdown">Submenu Left</a>
                <ul className="dropdown-menu shadow">
                  <li><a className="dropdown-item" href=""> Third level 1</a></li>
                  <li><a className="dropdown-item" href=""> Third level 2</a></li>
                  <li><a className="dropdown-item" href=""> Third level 3</a></li>
                  <li><a className="dropdown-item" href=""> Third level 4</a></li>
                  <li><a className="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>
              <li className="dropend">
                <a href="#" className="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Submenu Right</a>
                <ul className="dropdown-menu shadow">
                  <li><a className="dropdown-item" href=""> Second level 1</a></li>
                  <li><a className="dropdown-item" href=""> Second level 2</a></li>
                  <li><a className="dropdown-item" href=""> Second level 3</a></li>
                  <li className="dropend">
                    <a href="#" className="dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside">Let's go deeper!</a>
                    <ul className="dropdown-menu dropdown-submenu shadow">
                      <li><a className="dropdown-item" href=""> Third level 1</a></li>
                      <li><a className="dropdown-item" href=""> Third level 2</a></li>
                      <li><a className="dropdown-item" href=""> Third level 3</a></li>
                      <li><a className="dropdown-item" href=""> Third level 4</a></li>
                      <li className="dropend">
                        <a href="#" className="dropdown-item dropdown-toggle" data-bs-toggle="dropdown">Still don't have enough? Go much deeper!</a>
                        <ul className="dropdown-menu dropdown-submenu shadow">
                          <li><a className="dropdown-item" href=""> Third level 1</a></li>
                          <li><a className="dropdown-item" href=""> Third level 2</a></li>
                          <li><a className="dropdown-item" href=""> Third level 3</a></li>
                          <li><a className="dropdown-item" href=""> Third level 4</a></li>
                          <li><a className="dropdown-item" href=""> Third level 5</a></li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li><a className="dropdown-item" href=""> Third level 5</a></li>
                </ul>
              </li>
              <li><hr className="dropdown-divider"/></li>
              <li><a className="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">Link</a>
          </li>
          <li className="nav-item dropdown dropdown-mega position-static">
            <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" data-bs-auto-close="outside">Megamenu</a>
            <div className="dropdown-menu shadow">
              <div className="mega-content px-4">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-sm-4 col-md-3 py-4">
                      <h5>Pages</h5>
                      <div className="list-group">
                        <a className="list-group-item" href="#">Accomodations</a>
                        <a className="list-group-item" href="#">Terms & Conditions</a>
                        <a className="list-group-item" href="#">Privacy</a>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-3 py-4">
                      <h5>Card</h5>
                      <div className="card">
                  <img src="https://via.placeholder.com/320x180" className="img-fluid" alt="image"/>
                  <div className="card-body">
                    <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                  </div>
                </div>
                    </div>
                    <div className="col-12 col-sm-4 col-md-3 py-4">
                      <h5>Lot of Pages</h5>
                      <p>Lorem ipsum dolo sit achmet muhamed borlan de irtka.</p>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3 py-4">
                      <h5>Damn, so many</h5>
                      <div className="list-group">
                        <a className="list-group-item" href="#">Accomodations</a>
                        <a className="list-group-item" href="#">Terms & Conditions</a>
                        <a className="list-group-item" href="#">Privacy</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li>
        </ul>
        <form className="d-flex ms-auto">
            <div className="input-group">
                <input className="form-control border-0 mr-2" type="search" placeholder="Search" aria-label="Search"/>
                <button className="btn btn-primary border-0" type="submit">Search</button>
            </div>
        </form>
      </div>
    </div>
</nav>*/}
</div>
  );
};

export default Navemenu;