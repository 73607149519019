import React, {useEffect,useState} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import { Link } from "react-router-dom";
import { Images } from "../../ThemeConfig/Images";
import { Helmet } from 'react-helmet';
import './Blog.scss';
import Pagination from '../Pagination/Pagination';
import axios from "axios";


const Blog = () => {

  const [blogs , setBlogs] = useState([]);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
     getBlogs();
  }, []);



const url = 'https://x285-t7rb-jeug.n7.xano.io/api:a1md9bXd/blogs';
const getBlogs =()  =>{
    axios.get(`${url}`)
   .then((response)=>{
     const result = response.data;
      console.log('result');
      console.warn(result)
      setBlogs(response.data);
   })
   .catch(error=>console.error(`Error:${error}`))
}

function UnsafeComponent({ html }) {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }


  return (
    <div className='cmsPagesWrapper'>
    <Navemenu/>
    <Helmet>
      <meta charSet='utf-8'/>
      <title>Blog - The Good Biker</title>
      <meta name='discription' content='Check information about Blog posting of The Good Biker.'/>
      <meta name='keywords' content='TGB blogs' />
    </Helmet>
    <div className='container'>
    <div className='row'>
      <div className='col-12 col-lg-12'>
        <div className="staticBlogsInfo">
            <h2>Blogs</h2>
            <div className='row'>
     { blogs.map ((blog, id) =>{
        return (
          <div className='col-12 col-lg-4' key={blog?.id}>
                <a href={'/blog/'+blog.id} className='blogsListLink'>
                  <div className='blogsList'>
                    <div className='bannerImg'>
                      <img src={blog.banner.url} alt="blogImage" className='image img-fluid'/>
                      <div className="middle">&nbsp;</div>
                    </div>
                    <div className='blogsListInfo'>
                      <h2>{blog.title}</h2>
                      <div className='blogShortInfo'><UnsafeComponent html={blog.blog_description.substring(0, 150) + `...`} /></div>
                      <a href={'/blog/'+blog.id}>READ MORE</a>
                    </div>
                  </div>
                </a>
              </div>
           )
        })}

            </div>
           {/* { blogs.length >10 ?  <Pagination/> : '' } */}
          </div>
    </div>
    </div>
    </div>
    <Footer/>
    </div>
  );
};

export default Blog;