import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import { Images } from "../../ThemeConfig/Images";
import { Helmet } from 'react-helmet';
import './IndianMotorcycleRacing.scss';

const IndianMotorcycleRacing = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='siteMapWrapper'>
      <Navemenu/>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Indian Motorcycle Racing | Bike Competitions - TGB</title>
        <meta name='description' content='TGB participates in Indian Motorcycle Racing and bike competitions who enjoy riding motorcycles.'/>
        <meta name='keywords' content='Indian Motorcycle Racing, Bike Competitions' />
      </Helmet>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className="staticInfo">
              <h1>Indian Motorcycle Racing: Thrilling Two-Wheeled Competitions Await!</h1>
              <div className='bannerImg'>
                  <img src={Images.indian_bg} alt=""/>
              </div>
              <p>Can you picture the sleek motorbikes racing down the track with their engines screaming, tires screeching, and the exhilarating rush of wind? Motorcycle racing attracts followers worldwide because it is more than simply a sport; it is a symphony of strength, precision, and speed.</p>
              <p>But operating a high-performance bike at top speed could be just as challenging as navigating the nuances of the racing world. Don't worry; we're here to ignite your competitive excitement and assist you during this action-packed journey.</p>

              <div className='staticInfoList'>
                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.indian_sm_bg} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>Embrace Your Competitive Spirit with the Ultimate Racing Competitions</h4>
                    <p><b>Indian motorcycle racing</b> offers various races for every rider style, from rough dirt courses to sophisticated asphalt circuits. Our company thoroughly lists the most well-known racing events, whether you're a rookie trying to test your abilities or an experienced racer seeking renown. Enter the fascinating world of motorcycle racing competitions and prepare to win the races.</p>
                  </div>
                </div>

                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.indian_sm_bg1} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>TGB's Power Unleashed: Your Racing Partner</h4>
                    <p>We understand that motorcycle racing is a way of life rather than just a hobby. Our website is meant to be your most reliable travel companion. We are your one-stop shop for biker racing, offering a variety of resources like insights into the newest bike models, professional advice on how to sharpen your racing techniques and a vibrant community of like-minded spectators.</p>
                  </div>
                </div>

                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.indian_sm_bg2} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>Navigating Challenges: Professional Advice and Perspectives</h4>
                    <p>Planning, technique, and pure daring all go into racing. Every rider has unique difficulties, from honing turning skills to determining the ideal balance between speed and control.</p>
                    <p>The experienced racers who have mastered the tracks have provided authoritative information on our website. With the aid of TGB, you can improve your braking technique, navigate hairpin turns, and harness the unbridled power of your bike.</p>
                  </div>
                </div>

                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.indian_sm_bg3} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>Passionate Riders Coming Together: The Goodbiker Community</h4>
                    <p>The motorcycle racing environment is unique because of the camaraderie among riders. We have created a gathering place for devoted riders.</p>
                    <p>With other <b>Indian motorcycle racing</b> enthusiasts, you can exchange experiences, seek guidance, and develop lasting relationships. Our community is your one-stop shop for anything related to racing, from discussing the newest business trends to planning online races.</p>
                  </div>
                </div>

                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.indian_sm_bg4} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>Accepting Excellence: Workshops and Training</h4>
                    <p>Being a skilled racer requires dedication and constant improvement in addition to a drive for speed. We thus provide a range of training programs and courses catered to different skill levels.</p>
                    <p>Whether you're a novice attempting to lay a solid foundation or an experienced racer looking to hone your skills, our training courses cover every aspect of <b>bike competitions</b> to ensure you're always one step ahead on the track.</p>
                  </div>
                </div>
              </div>

              <div className='staticBottomInfo'>
                <h4>Conclusion</h4>
                <p>Indian bike racing is more than a sport; it's a way of life. Although the challenges are intimidating, facing the pressure to finish the course is important. By choosing the good biker as your reliable resource, you're not only stepping into the world of racing but also a dynamic community that inspires your goal and shares your excitement. It's time to revive your engines, face the challenges, and write your chapter about the exciting journey of an Indian motorcycle rider.</p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default IndianMotorcycleRacing;