import React, {useEffect} from 'react';
import Navemenu from '../Navemenu/Navemenu';
import Footer from '../Footer/Footer';
import { Images } from "../../ThemeConfig/Images";
import { Helmet } from 'react-helmet';
import './BikercommunityIndia.scss';

const BikercommunityIndia = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);
  return (
    <div className='siteMapWrapper'>
      <Navemenu/>
      <Helmet>
        <meta charSet='utf-8'/>
        <title>Biker Community in India - TGB</title>
        <meta name='discription' content='Biker Community in India - Join TGB Biker groups and be a part of Indian community of motorbike riders to go for long rides.'/>
        <meta name='keywords' content='Biker Community in India' />
      </Helmet>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-lg-12'>
            <div className="staticInfo">
              <h1>Revolutionizing the Biker Community: TGB's Solution to Common Challenges</h1>

              <div className='blockInfo'>
                <p>Have you ever thought about how to get past the challenges of being a part of the close-knit <b>biker community?</b> Finding other riders who match your interests and navigating maintenance difficulties can be difficult on the road. But the most important question is: Can these issues be resolved? We'd like to introduce you to The good biker, a leader in the biking sector who offers cutting-edge solutions to improve your riding experience.</p>
              </div>

              <div className='staticInfoList'>
                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.communityOne} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>Tailored Motorcycle Services</h4>
                    <p>To satisfy your specific demands, we provide a range of personalized motorbike services. Your vehicle will always be in top condition thanks to the professional technicians on our crew, whether it requires regular upkeep, alterations, or repairs. TGB, however, does not offer motorcycle services but instead suggests performance mechanics and garages. Stop the hassle of looking for a dependable mechanic.
                    </p>
                  </div>
                </div>

                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.communityTwo} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>Community Building Events</h4>
                    <p>One of the biggest challenges for bikers is finding a group of like-minded members in the <b>Biker community.</b> We do not provide motorcycle services, but we recommend performance garages and mechanics for your biking needs. These gatherings aren't just about getting somewhere; they're also about developing enduring relationships with fellow roadies who share your passion for seeing new places.
                    </p>
                  </div>
                </div>

                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.communityThree} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>Safety First</h4>
                    <p>Their first goal is the safety of the riders. Rider safety is a top priority for our business, and as such, we offer seminars and training sessions. Regardless of how knowledgeable or unskilled you are as a rider, our safety classes will provide you with the skills and knowledge to navigate the road safely and reduce accidents and injuries.</p>
                  </div>
                </div>

                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.communityFour} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>Exclusive Membership Benefits</h4>
                    <p>We offer a premium membership package with a number of benefits. It's crucial to remember that TGB advises performance garages and mechanics rather than offering motorbike services. Discounts on motorcycle gear and priority entry to our events and services are just a couple of the perks available to our members that enhance their motorcycling experience. Take your riding to a new level by being a part of the <b>TGB family.</b></p>
                  </div>
                </div>

                <div className='infoList'>
                  <div className='image'>
                    <img src={Images.communityFive} alt="List_Image"/>
                  </div>
                  <div className='info'>
                    <h4>Ride Beyond Boundaries</h4>
                    <p>Because we value the spirit of adventure, we urge our members to ride outside of their comfort zones. We arrange cross-country and international rides that allow you to travel and experience new areas. Escape the routine and embark on amazing adventures with us.</p>
                  </div>
                </div>

              </div>

              <div className='staticBottomInfo'>
                <p>The good biker (TGB) is a motorcycle community that goes above and beyond business to address issues that average bikers face. We reinvent biking in terms of upkeep, relationships, safety, and adventure. Join us for limitless rides and problem-solving.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default BikercommunityIndia;