export const Constants = {
  bannerSlider:[
    {
      id:'1',
      title:'Where Your Motorcycle Matters!',
      discription:'The Good Biker has emerged as the largest, strongest and the most trusted network of motorcycle riders across regions bonding them as a single family.',
    },
    {
      id:'2',
      title:'Ride, Connect, Thrive - The Good Biker Way!',
      discription:'The Good Biker has emerged as the largest, strongest and the most trusted network of motorcycle riders across regions bonding them as a single family.',
    },
    {
      id:'3',
      title:"The Hub of Every Biker's Adventure!",
      discription:'The Good Biker has emerged as the largest, strongest and the most trusted network of motorcycle riders across regions bonding them as a single family.',
    },
    {
      id:'4',
      title:"Fueling Passion, Igniting Connections",
      discription:'The Good Biker has emerged as the largest, strongest and the most trusted network of motorcycle riders across regions bonding them as a single family.',
    },
    {
      id:'5',
      title:"Your Journey, Your Story, Your Tribe",
      discription:'The Good Biker has emerged as the largest, strongest and the most trusted network of motorcycle riders across regions bonding them as a single family.',
    },
    {
      id:'6',
      title:"Travel Beyond Boundaries - The TGB Way",
      discription:'The Good Biker has emerged as the largest, strongest and the most trusted network of motorcycle riders across regions bonding them as a single family.',
    }
  ]
}